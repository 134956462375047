@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
}

#root {
  overflow-y: scroll;
}

.active-only {
  display: none;
}

.active .active-only {
  display: inherit;
}

.active .inactive-only {
  display: none;
}