.tracker-overlay {
    box-sizing: border-box;
    position: absolute;
    /* width: 16px;
    height: 16px; */
    width: 40px;
    height: 40px;
    /* background: #0098CD; */
    /* border-radius: 50%; */
    z-index: 2;
    /* border: 2px solid #ffffff; */
  }
  
  .tracker-overlay:before,
  .tracker-overlay:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    background: #0098CD;
    border-radius: 50%;
    z-index: 1;
    animation: tracker-overlay-pulse 1s linear infinite;
    visibility: hidden;
    opacity: 0.5;
  }
  
  .tracker-overlay:after {
    animation-delay: .2s;
  }
  
  @keyframes v-overlay-pulse {
    0% {
      opacity: .5;
      transform: translateZ(0) scale(0);
      visibility: visible;
    }
    to {
      opacity: 0;
      transform: translateZ(0) scale(1);
      visibility: visible;
    }
  }