.current-position-overlay {
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 16px;
  background: #0098CD;
  border-radius: 50%;
  z-index: 2;
  border: 2px solid #ffffff;
}

.current-position-overlay:before,
.current-position-overlay:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  background: #0098CD;
  border-radius: 50%;
  z-index: 1;
  animation: current-position-overlay-pulse 1s linear infinite;
  visibility: hidden;
  opacity: 0.5;
}

.current-position-overlay:after {
  animation-delay: .2s;
}

@keyframes current-position-overlay-pulse {
  0% {
    opacity: .5;
    transform: translateZ(0) scale(0);
    visibility: visible;
  }
  to {
    opacity: 0;
    transform: translateZ(0) scale(1);
    visibility: visible;
  }
}